<template>
  <log-quick-submit
    v-if="isEditing"
    v-model="comment"
    :placeholder="$t('ACTIVITY.COMMENTS.COMMENT_PLACEHOLDER')"
    :loading="loading"
    data-cy="log-comment-edit"
    @submit="updateComment"
  >
    <template #actionBar>
      <avatar-with-name :user="data.user" data-cy="log-comment-author" />
    </template>

    <template #buttons>
      <modal-buttons
        class="max-h-8 px-[0] bg-white"
        style="background-color: transparent !important"
        :disabled="!comment.trim() || loading"
        :on-cancel="cancelEditing"
        :on-save="updateComment"
      />
    </template>
  </log-quick-submit>

  <div v-else class="space-y-2" data-cy="log-comment">
    <div class="flex w-full justify-between space-x-1-5">
      <p
        class="text-gray-500 break-words overflow-hidden"
        data-cy="log-comment-content"
      >
        {{ comment }}
      </p>
      <action-menu-wrapper :items="menuItems" :offset="offset" teleport />
    </div>
    <div class="flex items-center w-full space-x-1-5 font-medium text-xs">
      <avatar-with-name :user="data.user" data-cy="log-comment-author" />
      <span>•</span>
      <span>
        {{ relativeDate }}
      </span>
    </div>
  </div>

  <confirm-modal
    v-if="showDeleteModal"
    :title="`${$t('COMMON.DELETE')} ${$t('ACTIVITY.COMMENTS.TITLE')}`"
    :description="
      $t('COMMON.DELETE_CONFIRMATION', {
        source: $t('ACTIVITY.COMMENTS.TITLE').toLowerCase()
      })
    "
    :confirm-button="$t('COMMON.DELETE')"
    confirm-button-type="error"
    :close="() => (showDeleteModal = false)"
    @confirm="deleteComment"
  />
</template>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import { computed, ref, type PropType, inject } from "vue";
import { formatTimeAgo } from "@/helpers/formatting";
import { useI18n } from "vue-i18n";
import { useCommunicationStore } from "@/stores/communication";
import { CommunicationLogContextKey } from "@/helpers/symbols";
import { usePromiseWrapper } from "@/hooks/common";
import { useNotification } from "@/hooks/notifications";

import AvatarWithName from "@/components/ui/AvatarWithName.vue";
import ActionMenuWrapper, {
  type MenuItem
} from "@/components/ui/ActionMenuWrapperV2.vue";
import LogQuickSubmit from "@/components/comunicationLogs/forms/LogQuickSubmit.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";

import type { CommunicationLogComment } from "@/models/communicationLogs";

const props = defineProps({
  data: {
    type: Object as PropType<CommunicationLogComment>,
    required: true
  }
});

const offset = { left: -58, top: 43 };

const context = inject(CommunicationLogContextKey, {
  type: null,
  logId: null
});

const { t } = useI18n();
const { showMessage } = useNotification();
const showDeleteModal = ref(false);

const isEditing = ref(false);
const comment = ref(props.data.comment);

const { deleteComment: storeDeleteComment, updateComment: storeUpdateComment } =
  useCommunicationStore();

const { fetchWrapper: dispatchDeleteComment } =
  usePromiseWrapper(storeDeleteComment);

const { fetchWrapper: dispatchUpdateComment, loading } = usePromiseWrapper(
  storeUpdateComment,
  {
    onError: () => {
      showMessage(t("COMMON.ERROR_OCCURRED", "error"));
      comment.value = props.data.comment;
    }
  }
);

const menuItems = computed(() => {
  const items: MenuItem[] = [
    {
      label: t("COMMON.EDIT"),
      handler: () => (isEditing.value = true)
    },
    {
      label: t("COMMON.DELETE"),
      handler: () => (showDeleteModal.value = true)
    }
  ];

  return items;
});

const relativeDate = computed(() => {
  return formatTimeAgo(props.data.created_at);
});

const cancelEditing = () => {
  comment.value = props.data.comment;
  isEditing.value = false;
};

const deleteComment = async () => {
  const { logId, type } = context;
  if (logId === null || type === null) {
    return;
  }
  await dispatchDeleteComment({
    commentId: props.data.id,
    logId,
    type
  });
  showDeleteModal.value = false;
};

const updateComment = async () => {
  const { logId, type } = context;
  if (logId === null || type === null) {
    return;
  }
  await dispatchUpdateComment({
    comment: comment.value,
    commentId: props.data.id,
    logId,
    type
  });
  isEditing.value = false;
};
</script>

<style scoped>
:deep(.dropdown) {
  @apply transform -translate-x-full -translate-y-5;
}
</style>
